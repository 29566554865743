import request, { hideRequestLoading } from '@/utils/request'

const BASE_URL = '/electric/machine/data-statistics'

export default {
  topBaseInfo() {
    hideRequestLoading()
    return request({
      url: `${BASE_URL}/top-base-info`,
      method: 'get'
    })
  },

  dayActiveDevice() {
    return request({
      url: `${BASE_URL}/day-active-device`,
      method: 'get'
    })
  }
}
